/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import styled, { withTheme } from "styled-components"
import { Helmet } from "react-helmet"

const StickyFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fefefe;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1em;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      heroImage: file(
        relativePath: {
          eq: "Moss Bryggeri Fabrikken med uteservering Element Arkitekter.jpg"
        }
      ) {
        childImageSharp {
          small: fluid(maxWidth: 768, maxHeight: 768, quality: 95) {
            ...GatsbyImageSharpFluid
          }
          medium: fluid(maxWidth: 992, quality: 95) {
            ...GatsbyImageSharpFluid
          }
          large: fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const HeroImage = styled.div`
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: 50%;
    height: 100vh;
    background-image: url('${data.heroImage.childImageSharp.small.src}');
    @media screen and (min-width: 768px) {
      background-image: url('${data.heroImage.childImageSharp.medium.src}');
    }
    @media screen and (min-width: 992px) {
      background-image: url('${data.heroImage.childImageSharp.large.src}');
    }
  `

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Moss Bryggeri,Moss-Bryggeri,Moss Malt,Moss Maltextrakt,Gernergården,Gerner gården,Henrich Gerners gate,Henrich Gerners gate Moss,Henrich Gerners gate 10,Verket Moss,Møllebyen Moss,Momentum"
        />
      </Helmet>
      <HeroImage>
        <Header siteTitle={data.site.siteMetadata.title} />

        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main>{children}</main>
        </div>
        <StickyFooter>
          Fauna Eiendom AS &bull; Element Arkitekter AS ©{" "}
          {new Date().getFullYear()} &bull; Laget av{" "}
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.digifix.no"
          >
            {" "}
            DigiFix AS
          </a>
        </StickyFooter>
      </HeroImage>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
